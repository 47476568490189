import './Portfolio.css';

import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

function Portfolio() {
  return (
    <div id="divPortfolio" className="container-fluid">
    <div className="Portfolio-left-align">
        <h1 className="display-4">Portfolio</h1>
    </div>
    <hr className="my-4" />  

    <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
        <div className="col">
          <div className="card mb-4 rounded-3 shadow-sm">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">The To Do</h4>
            </div>
            <div className="card-body">
              <Tooltip title="This portfolio item is currently under maintenance." arrow>                
                <Link to="/TheToDo" 
                className="btn btn-primary btn-lg disabled" aria-disabled="true" role="button">Visit Me!</Link>                
                <p className="lead">A basic To Do List.</p>                
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card mb-4 rounded-3 shadow-sm">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">The To Do - Android Version</h4>
            </div>
            <div className="card-body">
                  <a href="https://play.google.com/store/apps/details?id=com.app.thetodo" className="btn btn-primary btn-lg"
                      role="button">Visit Me!</a>
                <p className="lead">A To Do Application.</p>                
            </div>
          </div>
        </div>
        <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">In Progress</h4>
              </div>
              <div className="card-body">
                <Tooltip title="This portfolio item is currently being built." arrow>
                  <span className="d-inline-block" tabIndex="0">
                      <a href="about:blank" className="btn btn-primary btn-lg disabled" aria-disabled="true" 
                          role="button">Visit Me!</a>
                  </span>                    
                  <p className="lead">Coming Soon!</p>                  
                </Tooltip>                  
              </div>
            </div>
          </div>
    </div>
    </div>

  );
}

export default Portfolio;
