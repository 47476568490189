import './Banner.css';

function Banner() {
  return (
    <div className="Banner">
      <div className="p-2 mb-4 bg-light rounded-3">
        <div className="container-fluid py-3">
          <h1 className="display-4">Hi, My Name Is Al Khakhi</h1>
          <p className="lead">Welcome to my random ponderings. Please choose from the navigation menu above to learn more about me.</p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
