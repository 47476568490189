import './App.css';

import { Routes, Route } from 'react-router-dom'

import Navigation from './Navigation/Navigation';
import Banner from './Banner/Banner';
import About from './About/About';
import Resume from './Resume/Resume';
import Portfolio from './Portfolio/Portfolio';
import ToDoMain from './TheToDo/ToDoMain';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Banner />
      <Footer />     

      <Routes>
          <Route index path="/About" element={ <About /> } />
          <Route path="/Resume" element={ <Resume /> } />
          <Route path="/Portfolio" element={ <Portfolio /> } />
          <Route path="/TheToDo" element={ <ToDoMain /> } />          
          <Route path="/Contact" element={ <Contact /> } />          
      </Routes>             
    </div>
  );
}

export default App;
