import './ToDoMain.css';

import { Link } from 'react-router-dom';

function ToDoMain() {
  return (
    <div>
      <hr className="my-4" />
      <div className="p-2 mb-4 bg-light rounded-3">
        <div className="container-fluid py-3 ToDoMain-left-align">
            <h1 className="display-4">The To Do</h1><br />
            <p className="lead">This is a simple To Do list that allows you to track all your To Do list items for the current session. 
              A list of your existing To Do items are in the list further below.</p>
            <p className="lead">To get started, click the Add To Do Item button below</p>        
            <p className="lead">
              <Link to="/" className="btn btn-danger btn-lg" role="button" data-bs-toggle="modal" data-bs-target="#addToDoModal">Add To Do Item</Link>
            </p>      
        </div>
      </div>
      <hr className="my-4"></hr>
    </div>
  );
}

export default ToDoMain;
