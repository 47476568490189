import './Contact.css';

import emailjs from 'emailjs-com'
import { Alert, AlertTitle, Snackbar } from '@mui/material';

function Contact() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.send("service_yrf3mpe","template_0tx0ihe",{
      to_name: 'Al',
      from_name: e.target['txtName'].value,
      email_addr: e.target['txtEmail'].value,
      message: e.target['txtMessage'].value
      },'T7jfGgztfeoZqykXA');
    }

  return (
    <div id="mainGreeting" className="container-fluid lead Contact-left-align">
      <h1 className="display-4 Contact-header">Contact Me</h1>      
      <form onSubmit={sendEmail}>
        <div className="divFormName Contact-text" noValidate>
            <label htmlFor="txtName">Name:</label>
            <input type="text" className="form-control" id="txtName" aria-describedby="nameHelp" 
              placeholder="Enter your name" required />           
        </div><br />        
        <div className="divFormEmail Contact-text">
          <label htmlFor="txtEmail">Email address:</label>
          <input type="email" className="form-control" id="txtEmail" aria-describedby="emailHelp" 
          placeholder="Enter your email address" required />
          <small id="emailHelp" className="form-text text-muted">I'll never share your email with anyone else.</small>        
        </div><br />
        <div className="divFormTextArea Contact-text">
            <label htmlFor="formTextArea">Please explain the nature of your request/comment:</label>
            <textarea className="form-control" id="txtMessage" rows="4" required></textarea>                   
        </div><br />
        <div className="Contact-text"><button id="btnSubmit" type="submit" className="btn btn-primary">Submit</button></div>      
      </form>     
    </div>
  );
}

export default Contact;
