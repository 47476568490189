import './Navigation.css';

import { Link } from 'react-router-dom';
import Logo from '../Assets/logo.png';

function Navigation() {
  return (
    <div className="Navigation">
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark sticky-top">
  {/*--Brand/logo --*/}
  <Link to="/About" className="navbar-brand">
    <img src={Logo} height="50px" width="50px" alt=""/>   
  </Link>  
  {/*-- NavBar Toggle --*/}
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavBar" 
  aria-controls="mainNavBar" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="mainNavBar">
    {/*-- Links -- */}
    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
      <li className="nav-item">
        <Link to="/About" className="btn btn-outline-danger"
        aria-disabled="false" data-target=".navbar-collapse.show">About</Link>
      </li><br />
      <li className="nav-item">
        <Link to="/Resume" className="btn btn-outline-danger"
        aria-disabled="false" data-target=".navbar-collapse.show">Resume</Link>
      </li><br />   
      <li className="nav-item">
        <Link to="/Portfolio" className="btn btn-outline-danger"
        aria-disabled="false" data--target=".navbar-collapse.show">Portfolio</Link>
      </li><br />             
      <li className="nav-item">
        <Link to="/Contact" className="btn btn-outline-danger"
        aria-disabled="false" data--target=".navbar-collapse.show">Contact</Link>
       </li><br />  
    </ul>    
  </div>
</nav>    
    </div>
  );
}

export default Navigation;
