import './About.css';

function About() {
  return (
    <div className="About-left-align">
      <h1 className="display-4">About Me</h1>        
      <hr className="my-4"></hr>    
      <p className="lead">I am an experienced Systems and Support Analyst with proven skillsets in the area of Systems Analysis, 
                    Software Development, IT Project Management, IT Relationship Management, and Application Support. I have a strong 
                    Information Technology background and hold a Bachelor's Degree in Computer Information Systems. I am driven by a 
                    need to gain knowledge and improve the operational efficiency of my clients. Venturing into new domains of 
                    Information Technology is exciting for me as it provides me with the opportunity to learn and grow. I thrive on 
                    my ability to analyze a challenge and implement a solution that will greatly benefit my clients. Improving my 
                    clients ability to provide business value in their day to day work is extremely fulfilling for me. 
                    The solution to any challenge should not just resolve it and/or facilitate a need if it makes a client's business process 
                    inefficient. The solution to any challenge must allow my clients to add the most business value while providing them 
                    with the efficiency they need to grow. I've experienced the Information Technology sector through many different lenses 
                    and it has taught me the important role technology plays in providing business value for a client. <br /><br />
                    
                     
                    The Resume and Portfolio sections, linked in the navigation bar above, will demonstrate the experience and skillsets 
                    I can provide to prospective clients. Please feel free to engage with me through the Contact page; also linked in the 
                    navigation menu above.
      </p>
    </div>
  );
}

export default About;
