import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="Footer">
    
      <span className="Footer-text">Copyright &copy; { currentYear } - Website Developed By Al Khakhi</span>
    </div>
  );
}

export default Footer;
