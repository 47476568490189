import './Resume.css';

function Resume() {
  return (
    <div>
      <div id="divResume" className="container-fluid lead">
      <div className="Resume-left-align">
          <h1 className="display-4">Resume</h1>
      </div>
      <hr className="my-4" />  
      <p className="Resume-left-align">I am an Information Technology professional with an experienced background in Software Development, Systems Analysis, and IT Project Management. 
        With more than 10 years of experience at the City of Calgary and 13 years overall, in the Information Technology sector, I have been responsible for delivering, 
        implementing, and supporting large scale systems that are both public-facing and internal. I have executed the analysis, planning, design, development, testing, 
        and deployment of software applications and system processes. My roles within the Information Technology sector have ranged from IT Project Manager to Software Engineer. 
        I have strong technical, analytical, and pro-active problem solving skills. </p>
        
      <p className="Resume-left-align">My primary objective is to contribute to the needs of a corporation in the Information Technology sector where, within a challenging technology/user environment, 
        business value can be created and delivered to achieve the long-term goals of the organization.</p>

      <br />

      <h6 className="Resume-left-align-bold-red Resume-left-align"><strong>The full content of my resume is available upon request via the Contact page.</strong></h6>    
      <hr />
      <h2 className="display-6 Resume-left-align">Summary of Qualifications</h2>
      <ul className="Resume-left-align">
        <li>Possess the technical skills necessary to guide the design and development of Desktop/Web-based applications.</li>
        <li>Possess the analytic skills necessary to execute requirements gathering and systems analysis related activities.</li>
        <li>Possess the project management skills necessary for planning, implementing, and delivering net new IT projects.</li>
      </ul>
      <hr />
      <h2 className="display-6 Resume-left-align">Technical Summary</h2>
      <ul className="Resume-left-align">
        <li>Programming/Database (Java, Objective-C, VB, VB6, C#, TypeScript, Python, Oracle PL/SQL, Microsoft T-SQL, MySQL)</li>
        <li>Reporting (Microsoft SSRS, Active Reports, Microsoft Power BI)</li>
        <li>Web Development (XHTML, CSS, PHP, ASP.NET, XML, JavaScript, HTML5, CSS3, jQuery, BootStrap, Angular, React)</li>
        <li>Frameworks (Microsoft .NET 3.5-4, Microsoft .Net Core 3.1, CAAF *, MAF *, Blacksmith *)</li>
        <li>Development/Design Tools (Microsoft Visual Studio, Microsoft Visio, Microsoft Azure DevOps, Remedy 11, Oracle SQL Developer, 
          SQL Server Management Studio, Microsoft Power Platform)</li>
        <span className="Resume-left-align Resume-text-bold-red Resume-left-align">*Denotes City of Calgary-specific development framework.</span>
      </ul>
      <hr />
      <h2 className="display-6 Resume-left-align">Certifications/Training</h2>
      <ul className="Resume-left-align">
        <li>CAAF (Development Framework) *</li>
        <li>MAF (Development Framework) *</li>
        <li>Blacksmith (Development Framework)*</li>    
        <li>Responsive Web Development</li>
        <li>Software Solutions Methodology *</li>    
        <li>Influencing Without Authority *</li>
        <li>Introduction To Project Management *</li>
        <li>Project Management Methodology *</li>    
        <li>PMO Risk Management *</li>
        <li>Microsoft Power Platform Function Consultant</li>
        <li>Microsoft Power Platform Developer</li>    
        <li>Microsoft Certified: Azure Fundamentals</li>  
        <span className="Resume-left-align Resume-text-bold-red Resume-left-align">*Denotes City of Calgary-specific certifications.</span>
      </ul>
      <hr/>
      <h2 className="display-6 Resume-left-align">Key Projects</h2>
      <ul className="Resume-left-align">
        <li>AS Password Vault (Software Engineer) *</li>
        <li>Cheque Collector (Software Engineer) *</li>
        <li>Recreation ETS Management System (Software Engineer/PM)*</li>
        <li>West LRT File Transfer (Software Engineer) *</li>
        <li>Risk-Based Inspections (Software Engineer/PM) *</li>
        <li>The To Do (Software Engineer)</li>
        <span className="Resume-left-align Resume-text-bold-red Resume-left-align">*Denotes City of Calgary-specific projects.</span>        
      </ul>  
      </div>
      </div>
  );
}

export default Resume;
